/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import RichTextEditor from "../../components/RichTextEditor"
import history from "../../components/History"

const componentStyle = css`
    button.search,
    button.add {
        margin-top: 24px !important;
    }
`
class AddEndorsement extends React.Component{

    state = {
        id: "",
        name: "",
        number: "",
        appliesTo: "",
        edition: "",
        type: "",
        tankType: "",
        logicalOperator: "",
        tagRule: "",
        statesCsv: "",
        footerStatesCsv: "",
        displayOrder: "",
        xml: "",
        error: ""
    }
    
    componentDidMount = async ()=>{
        window.scrollTo(0,0)

        const { endorsementsContainer } = this.props

        if(endorsementsContainer.state.editEndorsement){            
            const endorsement = endorsementsContainer.state.editEndorsement

            this.setState({
                id: endorsement.id,
                name: endorsement.name,
                appliesTo: endorsement.appliesTo,
                number: endorsement.number,
                edition: endorsement.edition,
                type: endorsement.type,
                tankType: endorsement.tankType,
                logicalOperator: endorsement.logicalOperator,
                tagRule: endorsement.tagRule,
                statesCsv: endorsement.statesCsv,
                footerStatesCsv: endorsement.footerStatesCsv,
                displayOrder: endorsement.displayOrder,
                xml: endorsement.xml
            })
        }  
    }

    updateValue = (e)=>{
        e = e.currentTarget

        this.setState({
            [e.name]: e.value
        })
    }

    updateXML = (value)=>{
        this.setState({
            xml: value
        })
    }

    addEndorsement = async ()=>{

        const { endorsementsContainer } = this.props

        let result
        if(endorsementsContainer.state.editEndorsement){
            result = await endorsementsContainer.updateEndorsement(this.state)
        }else{
            result = await endorsementsContainer.addEndorsement(this.state)
        }

        if(!result){
            this.setState({
                error: "There was an unexpected problem. Please try again.",
            })
        }else{
            history.push("/endorsements")
        }
    }

    cancel = async ()=>{
        history.push("/endorsements")
    }

    render(){
        const { endorsementsContainer } = this.props

        return(
            <>
            <div css={componentStyle}>
                <div className="row margin-top">
                    <div className="col">
                        <h2>{endorsementsContainer.state.editEndorsement ? "Edit" : "Add"} Endorsement</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name">
                            Name:
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="name" 
                                name="name"
                                value={this.state.name}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="number">
                            Number:
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="number" 
                                name="number"
                                value={this.state.number}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="appliesTo">
                            Applies To:
                        </label>
                        <select className="form-control form-control-sm" 
                                        id="appliesTo"
                                        name="appliesTo"
                                        value={this.state.appliesTo}
                                        onChange={this.updateValue}>    
                            <option value="None">--- Select ---</option>
                            <option value="All">All</option>
                            <option value="Quote">Quote</option>
                            <option value="Policy">Policy</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="edition">
                            Edition:
                        </label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="edition" 
                                name="edition"
                                value={this.state.edition}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="displayOrder">
                            Display Order:
                        </label>
                        <input type="number" 
                                className="form-control form-control-sm" 
                                id="displayOrder" 
                                name="displayOrder"
                                value={this.state.displayOrder}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">
                            Type:
                        </label>
                        <select className="form-control form-control-sm" 
                                        id="type"
                                        name="type"
                                        value={this.state.type}
                                        onChange={this.updateValue}>
                            <option value="None">--- Select ---</option>
                            <option value="Endorsement">Endorsement</option>
                            <option value="StateStuffer">StateStuffer</option>
                            <option value="PolicyDocument">PolicyDocument</option>
                            <option value="FinalDocument">FinalDocument</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tankType">
                            Tank Type:
                        </label>
                        <select className="form-control form-control-sm" 
                                        id="tankType"
                                        name="tankType"
                                        value={this.state.tankType}
                                        onChange={this.updateValue}>
                            <option value="None">--- Select ---</option>
                            <option value="Ust">UST</option>
                            <option value="Ast">AST</option>
                            <option value="Both">Both</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="logicalOperator">
                            Logical Operator:
                        </label>
                        <select className="form-control form-control-sm" 
                                        id="logicalOperator"
                                        name="logicalOperator"
                                        value={this.state.logicalOperator}
                                        onChange={this.updateValue}>    
                            <option value="None">--- Select ---</option>
                            <option value="And">And</option>
                            <option value="Or">Or</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tagRule">
                            Tag Rule:
                        </label>
                        <textarea type="text" 
                                className="form-control form-control-sm" 
                                id="tagRule" 
                                name="tagRule"
                                onChange={this.updateValue}
                                value={this.state.tagRule} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="statesCsv">
                            States CSV:
                        </label>
                        <textarea type="text" 
                                className="form-control form-control-sm" 
                                id="statesCsv" 
                                name="statesCsv"
                                onChange={this.updateValue}
                                value={this.state.statesCsv} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="footerStatesCsv">
                            Footer CSV:
                        </label>
                        <textarea type="text" 
                                className="form-control form-control-sm" 
                                id="footerStatesCsv" 
                                name="footerStatesCsv"
                                onChange={this.updateValue}
                                value={this.state.footerStatesCsv} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    { (this.state.xml || !endorsementsContainer.state.editEndorsement) &&
                        <div className="form-group">
                            <label htmlFor="xml">
                                XML:
                            </label>
                            <RichTextEditor content={this.state.xml}
                                            updateValue={this.updateXML} />
                        </div>
                    }                    
                </div>
            </div>

            {this.state.error !== "" &&
                <div className="row">
                    <div className="col-sm-12">
                        <p className="error">{this.state.error}</p>
                    </div>
                </div>
            }

            <div className="row button-group">
                <div className="col-sm-12">
                    <button className="btn btn-sm btn-primary float-right"
                            onClick={this.addEndorsement}
                            disabled={endorsementsContainer.state.isLoading}>Submit</button>
                    <button className="btn btn-sm btn-secondary float-right"
                            onClick={this.cancel}
                            disabled={endorsementsContainer.state.isLoading}>Cancel</button>
                    
                </div>
            </div>
            </>
        )
    }   
}

export default AddEndorsement