/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import { formatDate } from "../../helpers/FormatDate";

const componentStyle = css``;
class AgencyApplication extends React.Component {
  state = {
    id: "",
    status: "",
  };

  componentDidMount = () => {
    const { application } = this.props;
    this.setState({
      id: application.id,
    });
  };

  updateApplicationStatus = async (e) => {
    const { applicationsContainer, toggleStatusMessageOpen } = this.props;
    e = e.currentTarget;

    await applicationsContainer.setState({
      currentApplicationId: this.state.id,
      currentApplicationStatus: e.value,
    });

    toggleStatusMessageOpen();
  };

  viewApplication = () => {
    const { viewApplication } = this.props;
    viewApplication(this.state.id);
  };

  getComments = async () => {
    const { applicationsContainer, toggleComments } = this.props;

    toggleComments();

    applicationsContainer.getComments(this.state.id);
  };

  render() {
    const { application, applicationsContainer } = this.props;

    const app = applicationsContainer.state.attentionApplicationsMap.get(
      application.id
    );

    return (
      <tr css={componentStyle}>
        <td>{app.friendlyId}</td>
        <td>{app.namedInsured}</td>
        <td>{app.brokerCompanyName}</td>
        <td>{app.accountName}</td>
        <td>
          <a href={"mailto:" + app.accountEmail}>{app.accountEmail}</a>
        </td>
        <td>{app.customerNumber}</td>
        <td>{formatDate(app.dateStartedUtc)}</td>
        <td>{formatDate(app.dateSubmittedUtc)}</td>
        <td>{app.version}</td>
        <td>{app.status}</td>
        <td>{app.transaction}</td>
        <td>
          <button className="anchor" onClick={this.viewApplication}>
            View
          </button>
        </td>
        <td>
          <select
            className="form-control form-control-sm"
            id="status"
            name="status"
            value={this.state.status}
            onChange={this.updateApplicationStatus}
          >
            <option value="">--- Select ---</option>
            {applicationsContainer.state.actionableStatuses.map(
              (status, index) =>
                ((app.applicationTotal !== "$0" &&
                  status.id === "ApprovedToBind") ||
                  status.id !== "ApprovedToBind") && (
                  <option key={index} value={status.id}>
                    {status.name}
                  </option>
                )
            )}
          </select>
        </td>
        <td>
          <button className="anchor" onClick={this.getComments}>
            Comments
          </button>
        </td>
      </tr>
    );
  }
}

export default AgencyApplication;
