/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import { getRecordsPerPage } from "../../constants/Pagination";
import { formatDate } from "../../helpers/FormatDate";
import AttentionApplication from "./AttentionApplication";
import Pagination from "../../components/Pagination";
import TableSort from "../../components/TableSort";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import ReviewApplication from "./ReviewApplication";

const componentStyle = css`
  margin-top: 15px;

  .applicationData h3 {
    width: 100%;
    border-bottom: solid 2px #cc0000;
    padding-bottom: 10px;
  }

  .applicationData h4 {
    font-size: 16px;
    line-height: 16px;
    color: #7694aa;
    padding-bottom: 10px;
  }

  .applicationData table {
    margin-top: 0;
    border-top: none;
  }

  td.label {
    width: 250px;
    padding-right: 20px;
    text-align: right;
  }

  p.none-no-top-margin,
  ul.reasons {
    margin-top: -20px;
  }

  ul.reasons {
    margin-top: -10px;
  }
`;
class AttentionApplications extends React.Component {
  state = {
    totalRecords: 0,
    recordsPerPage: getRecordsPerPage(),
    totalPages: 0,
    currentPage: 1,
    orderBy: "NamedInsuredName",
    sort: {
      FriendlyId: null,
      NamedInsured: "asc",
      BrokerCompanyName: null,
      AccountName: null,
      CustomerNumber: null,
      DateStarted: null,
      DateSubmitted: null,
      Version: null,
      Status: null,
      Transaction: null,
    },
    viewApplicationVisible: false,
  };

  componentDidMount = async () => {
    const { applicationsContainer } = this.props;
    await applicationsContainer.getActionableStatuses();
    this.getAttentionApplications();
  };

  getAttentionApplications = async () => {
    const { applicationsContainer } = this.props;
    const totalRecords = await applicationsContainer.getAttentionApplications(
      this.state.currentPage,
      getRecordsPerPage(),
      this.state.orderBy,
      this.state.sort[this.state.orderBy] === "asc" ? true : false
    );
    await this.setState({
      totalRecords: totalRecords,
      totalPages: Math.ceil(totalRecords / this.state.recordsPerPage),
    });
  };

  sortTable = async (orderBy, direction) => {
    let tempSort = {};

    for (const key of Object.keys(this.state.sort)) {
      tempSort[key] = null;
    }

    if (!direction) {
      tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc";
    } else {
      tempSort[orderBy] = direction;
    }

    await this.setState({
      orderBy: orderBy,
      sort: tempSort,
      currentPage: 1,
    });

    this.getAttentionApplications();
  };

  changePage = async (page) => {
    await this.setState({
      currentPage: page,
    });

    this.getAttentionApplications();
  };

  toggleViewApplication = () => {
    this.setState({
      viewApplicationVisible: this.state.viewApplicationVisible ? false : true,
    });
  };

  viewApplication = async (id) => {
    const { applicationsContainer } = this.props;

    this.toggleViewApplication();

    await applicationsContainer.setState({
      currentApplicationId: id,
      currentApplication: null,
      coverages: [],
      locations: [],
      abovegroundStorageTanks: [],
      undergroundStorageTanks: [],
      requirementQuestions: [],
      additionalNamedInsured: [],
      additionalInsured: [],
      total: "",
    });

    await applicationsContainer.getLocationSites();
    await applicationsContainer.getTankAges();
    await applicationsContainer.getConstructions();
    await applicationsContainer.getCapacities();
    await applicationsContainer.getContents();
    await applicationsContainer.getDeductibles();
    await applicationsContainer.getPipeAges();
    await applicationsContainer.getUndergroundLeakDetectionTypes();
    await applicationsContainer.getUndergroundPipeLineConstructions();
    await applicationsContainer.getCoverageLimits();

    await applicationsContainer.getApplication(id);
    await applicationsContainer.getCoverages();
    await applicationsContainer.getLocations();
    await applicationsContainer.getAbovegroundStorageTanks();
    await applicationsContainer.getUndergroundStorageTanks();
    await applicationsContainer.getRequirementQuestions();
    await applicationsContainer.getTotal();

    //await applicationsContainer.viewApplication(id);

    await applicationsContainer.setState({
      isLoading: false,
    });
  };

  render() {
    const { applicationsContainer, toggleComments, toggleStatusMessageOpen } =
      this.props;
    const curApp = applicationsContainer.state.currentApplication;

    return (
      <div css={componentStyle}>
        <h3>Applications Requiring Attention</h3>

        <div className="row">
          <div className="col">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("FriendlyId")}
                      direction={this.state.sort.FriendlyId}
                    >
                      ID Number
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("NamedInsured")}
                      direction={this.state.sort.NamedInsured}
                    >
                      First Name Insured
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("BrokerCompanyName")}
                      direction={this.state.sort.BrokerCompanyName}
                    >
                      Agency Name
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("AccountName")}
                      direction={this.state.sort.AccountName}
                    >
                      Contact Name
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("AccountName")}
                      direction={this.state.sort.AccountEmail}
                    >
                      Contact Email
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("CustomerNumber")}
                      direction={this.state.sort.CustomerNumber}
                    >
                      Customer Number
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("DateStarted")}
                      direction={this.state.sort.DateStarted}
                    >
                      Date Started
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("DateSubmitted")}
                      direction={this.state.sort.DateSubmitted}
                    >
                      Date Submitted
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("Version")}
                      direction={this.state.sort.Version}
                    >
                      Version
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("Status")}
                      direction={this.state.sort.Status}
                    >
                      Status
                    </TableSort>
                  </th>
                  <th>
                    <TableSort
                      onClick={() => this.sortTable("Transaction")}
                      direction={this.state.sort.Transaction}
                    >
                      Renewals vs New Business
                    </TableSort>
                  </th>
                  <th>Review</th>
                  <th>Change Status</th>
                  <th>View Comments</th>
                </tr>
              </thead>
              <tbody>
                {applicationsContainer.state.attentionApplications.map(
                  (application) => (
                    <AttentionApplication
                      key={application.id}
                      applicationsContainer={applicationsContainer}
                      application={application}
                      viewApplication={this.viewApplication}
                      toggleComments={toggleComments}
                      toggleStatusMessageOpen={toggleStatusMessageOpen}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        {this.state.totalRecords > 1 && (
          <div className="row">
            <div className="col-sm-12">
              <Pagination
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                changePage={this.changePage}
              />
            </div>
          </div>
        )}

        {this.state.viewApplicationVisible && (
          <Modal close={this.toggleViewApplication}>
            {!curApp && <Spinner color="#cc0000" />}

            {curApp && (
              <>
                <div className="row applicationData">
                  <div className="col">
                    <h4>Reasons for Review</h4>
                    <ul className="reasons">
                      {curApp.underwriterReferralReasons.map(
                        (reason, index) => (
                          <li key={index}>{reason}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>

                <div className="row applicationData margin-top">
                  <div className="col">
                    <h3>Named Insured Information</h3>
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <td className="label">Customer Company:</td>
                          <td>{curApp.namedInsured.name}</td>
                        </tr>
                        <tr>
                          <td className="label">Customer Contact:</td>
                          <td>
                            {curApp.namedInsured.firstName +
                              " " +
                              curApp.namedInsured.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Phone Number:</td>
                          <td>{curApp.namedInsured.phoneNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">Company Email:</td>
                          <td>{curApp.namedInsured.email}</td>
                        </tr>
                        <tr>
                          <td className="label">Address Line 1:</td>
                          <td>{curApp.namedInsured.addressLine1}</td>
                        </tr>
                        <tr>
                          <td className="label">Address Line 2:</td>
                          <td>{curApp.namedInsured.addressLine2}</td>
                        </tr>
                        <tr>
                          <td className="label">Company City:</td>
                          <td>{curApp.namedInsured.city}</td>
                        </tr>
                        <tr>
                          <td className="label">Company State:</td>
                          <td>{curApp.namedInsured.state}</td>
                        </tr>
                        <tr>
                          <td className="label">Company Zip:</td>
                          <td>{curApp.namedInsured.postalCode}</td>
                        </tr>
                        <tr>
                          <td className="label">Customer Number:</td>
                          <td>{curApp.namedInsured.customerNumber}</td>
                        </tr>
                        <tr>
                          <td className="label">For Business:</td>
                          <td>
                            {curApp.acceptedNonIndividualPolicyDisclaimer
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">Effective Date:</td>
                          <td>{formatDate(curApp.dateEffectiveUtc)}</td>
                        </tr>
                        <tr>
                          <td className="label">Expiration Date:</td>
                          <td>{formatDate(curApp.dateExpirationUtc)}</td>
                        </tr>
                        <tr>
                          <td className="label">Different than 1 year:</td>
                          <td>{curApp.isMultiYearTerm ? "Yes" : "No"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h3>Coverages</h3>
                    {applicationsContainer.state.coverages.length === 0 && (
                      <p>None</p>
                    )}
                    {applicationsContainer.state.coverages.length > 0 && (
                      <table className="table table-sm">
                        <tbody>
                          {applicationsContainer.state.coverages.map(
                            (coverage, index) => (
                              <tr key={index}>
                                <td>
                                  {
                                    applicationsContainer.state.coveragesMap.get(
                                      coverage
                                    ).name
                                  }
                                </td>
                                <td className="label">
                                  {
                                    applicationsContainer.state.coveragesMap.get(
                                      coverage
                                    ).insuringAgreementLabel
                                  }
                                </td>
                                <td>
                                  {applicationsContainer.state.coveragesMap.get(
                                    coverage
                                  ).field1Value === "true"
                                    ? "Yes"
                                    : applicationsContainer.state.coveragesMap.get(
                                        coverage
                                      ).field1Value === "false"
                                    ? "No"
                                    : applicationsContainer.state.valuesMap.get(
                                        applicationsContainer.state.coveragesMap.get(
                                          coverage
                                        ).field1Value
                                      )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h3>Locations</h3>
                    {applicationsContainer.state.locations.length === 0 && (
                      <p>None</p>
                    )}
                    {applicationsContainer.state.locations.length > 0 && (
                      <>
                        {applicationsContainer.state.locations.map(
                          (loc, index) => (
                            <table key={index} className="table table-sm">
                              <tbody>
                                <tr>
                                  <td className="label">Location Number:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.locationsMap.get(
                                        loc
                                      ).locationNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Site Use:</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.locationsMap.get(
                                        loc
                                      ).siteUse
                                    )}{" "}
                                    {applicationsContainer.state.locationsMap.get(
                                      loc
                                    ).otherSiteUse
                                      ? " - " +
                                        applicationsContainer.state.locationsMap.get(
                                          loc
                                        ).otherSiteUse
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Street Address:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.locationsMap.get(
                                        loc
                                      ).address
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">City:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.locationsMap.get(
                                        loc
                                      ).city
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">State:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.locationsMap.get(
                                        loc
                                      ).state
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Zip Code:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.locationsMap.get(
                                        loc
                                      ).postalCode
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h3>Aboveground Storage Tanks</h3>
                    {applicationsContainer.state.abovegroundStorageTanks
                      .length === 0 && <p>None</p>}
                    {applicationsContainer.state.abovegroundStorageTanks
                      .length > 0 && (
                      <>
                        {applicationsContainer.state.abovegroundStorageTanks.map(
                          (ast, index) => (
                            <table key={index} className="table table-sm">
                              <tbody>
                                <tr>
                                  <td className="label">Location:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).location.address
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Tank Age:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).tankAgeInNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Construction:</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).construction
                                    )}{" "}
                                    {applicationsContainer.state.abovegroundStorageTanksMap.get(
                                      ast
                                    ).otherConstruction
                                      ? " - " +
                                        applicationsContainer.state.abovegroundStorageTanksMap.get(
                                          ast
                                        ).otherConstruction
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Capacity:</td>
                                  <td>
                                    {
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).capacityInNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Contents:</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).contents
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Leak Detection:</td>
                                  <td>
                                    {applicationsContainer.state.abovegroundStorageTanksMap.get(
                                      ast
                                    ).leakDetection === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">
                                    Secondary Containment:
                                  </td>
                                  <td>
                                    {applicationsContainer.state.abovegroundStorageTanksMap.get(
                                      ast
                                    ).secondaryContainment === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">
                                    Retroactive Date on Existing Policy:
                                  </td>
                                  <td>
                                    {formatDate(
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).retroactiveDateOnExistingPolicyUtc
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Deductible:</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.abovegroundStorageTanksMap.get(
                                        ast
                                      ).deductible
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h3>Underground Storage Tanks</h3>
                    {applicationsContainer.state.undergroundStorageTanks
                      .length === 0 && <p>None</p>}

                    {applicationsContainer.state.undergroundStorageTanks
                      .length > 0 && (
                      <>
                        {applicationsContainer.state.undergroundStorageTanks.map(
                          (ust, index) => (
                            <table key={index} className="table table-sm">
                              <tbody>
                                <tr>
                                  <td className="label">Location</td>
                                  <td>
                                    {
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).location.address
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Tank Age</td>
                                  <td>
                                    {
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).tankAgeInNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Construction</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).construction
                                    )}{" "}
                                    {applicationsContainer.state.undergroundStorageTanksMap.get(
                                      ust
                                    ).otherConstruction
                                      ? " - " +
                                        applicationsContainer.state.undergroundStorageTanksMap.get(
                                          ust
                                        ).otherConstruction
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Capacity</td>
                                  <td>
                                    {
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).capacityInNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Contents</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).contents
                                    )}{" "}
                                    {applicationsContainer.state.undergroundStorageTanksMap.get(
                                      ust
                                    ).otherContents
                                      ? " - " +
                                        applicationsContainer.state.undergroundStorageTanksMap.get(
                                          ust
                                        ).otherContents
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Leak Detection</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).typeOfLeakDetection
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">
                                    Retroactive Date on Existing Policy
                                  </td>
                                  <td>
                                    {formatDate(
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).retroactiveDateOnExistingPolicyUtc
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Deductible</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).deductible
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">
                                    Tank Tightness Test Passed within last 12
                                    months
                                  </td>
                                  <td>
                                    {applicationsContainer.state.undergroundStorageTanksMap.get(
                                      ust
                                    ).tankTightnessTestPassed === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Line Construction</td>
                                  <td>
                                    {applicationsContainer.state.valuesMap.get(
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).pipeLineConstruction
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Pipe Age</td>
                                  <td>
                                    {
                                      applicationsContainer.state.undergroundStorageTanksMap.get(
                                        ust
                                      ).pipeAgeInNumber
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="label">Leak Detection:</td>
                                  <td>
                                    {applicationsContainer.state.undergroundStorageTanksMap.get(
                                      ust
                                    ).pipeLeakDetection === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h3>Requirement Questions</h3>

                    <table className="table table-sm">
                      <tbody>
                        {applicationsContainer.state.requirementQuestions.map(
                          (question, index) => (
                            <tr key={index}>
                              <td>
                                {index + 1}.{" "}
                                {
                                  applicationsContainer.state.requirementQuestionsMap.get(
                                    question
                                  ).label
                                }{" "}
                                {applicationsContainer.state.requirementQuestionsMap.get(
                                  question
                                ).value === "yes"
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h3>Endorsement Questions</h3>

                    <p className="margin-top">
                      Certificate for financial assurance certificate?{" "}
                      {curApp.requestedCertificateForFinancialAssurance
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h4>Additional Named Insured</h4>
                    {applicationsContainer.state.additionalNamedInsured.size ===
                      0 && <p className="none-no-top-margin">None</p>}
                    {applicationsContainer.state.additionalNamedInsured.size >
                      0 && (
                      <>
                        {Array.from(
                          applicationsContainer.state.additionalNamedInsured
                        ).map((ani, index) => (
                          <table key={index} className="table table-sm">
                            <tbody>
                              <tr>
                                <td className="label">
                                  {
                                    applicationsContainer.state.additionalNamedInsuredMap.get(
                                      ani
                                    ).name
                                  }
                                  <br />
                                  {
                                    applicationsContainer.state.additionalNamedInsuredMap.get(
                                      ani
                                    ).addressLine1
                                  }
                                  <br />
                                  {applicationsContainer.state.additionalNamedInsuredMap.get(
                                    ani
                                  ).addressLine2 && (
                                    <>
                                      {
                                        applicationsContainer.state.additionalNamedInsuredMap.get(
                                          ani
                                        ).addressLine2
                                      }
                                      <br />
                                    </>
                                  )}
                                  {
                                    applicationsContainer.state.additionalNamedInsuredMap.get(
                                      ani
                                    ).city
                                  }
                                  ,{" "}
                                  {
                                    applicationsContainer.state.additionalNamedInsuredMap.get(
                                      ani
                                    ).state
                                  }{" "}
                                  {
                                    applicationsContainer.state.additionalNamedInsuredMap.get(
                                      ani
                                    ).postalCode
                                  }
                                </td>
                                <td>
                                  Is the proposed Additional Named Insured the
                                  owner of an insured tank; the operator of an
                                  insured tank; the landlord of a location where
                                  an insured tank is located; or, an entity
                                  related to any of the preceding groups?{" "}
                                  {applicationsContainer.state.additionalNamedInsuredMap.get(
                                    ani
                                  ).isTankOwner
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h4>Additional Insured</h4>
                    {applicationsContainer.state.additionalNamedInsured.size ===
                      0 && <p className="none-no-top-margin">None</p>}
                    {applicationsContainer.state.additionalNamedInsured.size >
                      0 && (
                      <>
                        {Array.from(
                          applicationsContainer.state.additionalInsured
                        ).map((ani, index) => (
                          <table key={index} className="table table-sm">
                            <tbody>
                              <tr>
                                <td className="label">
                                  {
                                    applicationsContainer.state.additionalInsuredMap.get(
                                      ani
                                    ).name
                                  }
                                </td>
                                <td>
                                  Is the proposed Additional Insured the owner
                                  of an insured tank; the operator of an insured
                                  tank; the landlord of a location where an
                                  insured tank is located; or, an entity related
                                  to any of the preceding groups?{" "}
                                  {applicationsContainer.state.additionalInsuredMap.get(
                                    ani
                                  ).isTankOwner
                                    ? "Yes"
                                    : "No"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className="row margin-top applicationData">
                  <div className="col">
                    <h4>Notice of cancellation to a third party entities</h4>
                    {!curApp.noticeOfCancellationEntity1 &&
                      !curApp.noticeOfCancellationEntity2 && (
                        <p className="none-no-top-margin">None</p>
                      )}
                    {(curApp.noticeOfCancellationEntity1 ||
                      curApp.noticeOfCancellationEntity2) && (
                      <>
                        <table className="table table-sm">
                          <tbody>
                            {curApp.noticeOfCancellationEntity1 && (
                              <tr>
                                <td>{curApp.noticeOfCancellationEntity1}</td>
                                <td>
                                  {curApp.noticeOfCancellationEntity1Address}
                                </td>
                              </tr>
                            )}
                            {curApp.noticeOfCancellationEntity2 && (
                              <tr>
                                <td>{curApp.noticeOfCancellationEntity2}</td>
                                <td>
                                  {curApp.noticeOfCancellationEntity2Address}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>

                <div className="row applicationData">
                  <div className="col">
                    <p>Terrorism coverage? {curApp.tria ? "Yes" : "No"}</p>
                  </div>
                </div>

                {applicationsContainer.state.total && (
                  <div className="row margin-top applicationData">
                    <div className="col">
                      <h4>Total: {applicationsContainer.state.total}</h4>
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default AttentionApplications;
