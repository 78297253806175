/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Endorsement from "./Endorsement"
import history from "../../components/History"

const componentStyle = css`
`
class Endorsements extends React.Component{

    componentDidMount(){        
        window.scrollTo(0,0)

        const { endorsementsContainer } = this.props
        endorsementsContainer.getEndorsements()
    }

    addEndorsement = async () =>{
        const { endorsementsContainer } = this.props
        await endorsementsContainer.setState({
            editEndorsement: null
        })
        history.push("/endorsements/endorsement")
    }
            
    render(){
        const { endorsementsContainer } = this.props
        
        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h2>Endorsements</h2>
                    </div>
                </div>

                <div className="row button-group">
                    <div className="col-sm-12">                        
                        <button className="btn btn-sm btn-primary float-right add"
                                onClick={this.addEndorsement}>
                            Add Endorsement
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Number</th>
                                    <th>Edition</th>
                                    <th colSpan="2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {endorsementsContainer.state.endorsements.map(endorsement=>(
                                    
                                    <Endorsement key={endorsement.id}
                                                 endorsement={endorsement}
                                                 endorsementsContainer={endorsementsContainer}/>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>          
        )
    }   
}

export default Endorsements